import { ComponentRef, ListViewRef } from './types';

export const scrollToComponentInList = (
  listViewRef: ListViewRef,
  componentRef: ComponentRef
) => {
  if (!componentRef.current || !listViewRef.current) return;

  calculateComponentPosition(listViewRef, componentRef)
    .then((coordinates) => {
      scrollToView({ coordinates, listViewRef });
    })
    .catch((error) => {
      console.error('Error calculating component position:', error);
    });
};

const calculateComponentPosition = (
  listViewRef: ListViewRef,
  targetViewRef: ComponentRef
): Promise<{ x: number; y: number }> => {
  return new Promise((resolve, reject) => {
    if (!targetViewRef.current || !listViewRef.current) {
      return reject('Invalid references');
    }

    const listViewNode = listViewRef.current.getScrollableNode();

    targetViewRef.current.measureLayout(
      listViewNode,
      (x, y) => resolve({ x, y }),
      reject // Reject if measureLayout fails
    );
  });
};

type ScrollToViewParams = {
  coordinates: { x: number; y: number };
  listViewRef: ListViewRef;
};

const scrollToView = ({
  coordinates: { y },
  listViewRef
}: ScrollToViewParams) =>
  listViewRef?.current?.scrollToOffset({
    offset: y,
    animated: true
  });
