import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@components/Column';
import { FilterIcon } from '@components/Icon';
import { Link } from '@components/Link';
import { useModal } from '@components/Modal';
import { Row } from '@components/Row';
import { ApplicationFilterValues } from '../../types';
import { AdvancedFiltersModal, FilteredStatusPill } from './components';

type Props = {
  appliedFiltersCount: number;
  setApplicationFilters: (filter: ApplicationFilterValues) => void;
  shouldShowActions: boolean;
  selectedApplicationsTab: 'matched' | 'other';
};

export const ApplicationsFilterSortBar = ({
  shouldShowActions,
  setApplicationFilters,
  appliedFiltersCount,
  selectedApplicationsTab
}: Props) => {
  const {
    openModal: openFilterModal,
    closeModal: closeFilterModal,
    isModalOpen: isFilterModalOpen
  } = useModal();

  const ref = useRef<{
    clearAllFilters: () => void;
    sendClearAllFiltersTrackingEvent: (eventType: string) => void;
  }>();

  return (
    <Column
      justifyContent="space-between"
      alignItems="flex-start"
      padding="medium"
      tone="transparent"
      zIndex={1000}
      spacing="medium"
    >
      <Row
        paddingTop="xsmall"
        paddingLeft="xxsmall"
        tone="transparent"
        alignItems="center"
        spacing="medium"
      >
        {shouldShowActions ? (
          <>
            <FilterLink onPressLink={openFilterModal} />

            <AdvancedFiltersModal
              ref={ref}
              setApplicationFilters={setApplicationFilters}
              visible={isFilterModalOpen}
              onRequestClose={closeFilterModal}
              selectedApplicationsTab={selectedApplicationsTab}
            />
          </>
        ) : null}
      </Row>

      {appliedFiltersCount > 0 ? (
        <Row paddingLeft="xxsmall" tone="transparent" alignItems="center">
          <FilteredStatusPill
            onPressClearFilters={() => {
              ref.current?.clearAllFilters();
              ref.current?.sendClearAllFiltersTrackingEvent(
                'Application filters pill cleared'
              );
            }}
            filteredCount={appliedFiltersCount}
          />
        </Row>
      ) : null}
    </Column>
  );
};

const FilterLink = ({ onPressLink }: { onPressLink: () => void }) => {
  const { t } = useTranslation();

  return (
    <Link
      icon={<FilterIcon variant="outline" size="large" />}
      variant="secondary"
      size="standard"
      onPress={() => onPressLink()}
      testID="applications-filter-filter-link"
    >
      {t('applications.applicationsFilter.action.filter')}
    </Link>
  );
};
