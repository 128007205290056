//eslint-disable-line no-unused-vars
import 'core-js/stable';
import debug from 'debug';
//Do not put any other imports above this line.
import * as ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { disableSDK } from '@braze/web-sdk';
import { RouteMap } from '@common/components/RouteMap';
import { inNativeApp } from '@src/joraEmployer/whereAmIRunning';
import { history, store } from '@store/store';
import { BffProvider } from '@trpcBff';
import { joraTheme } from '@theme';
import { DesignSystemProvider } from '@theme/DesignSystemProvider';
// eslint-disable-next-line no-unused-vars
import styles from '../app.scss';
// eslint-disable-next-line no-unused-vars
import thirdParty from '../third-party.scss';
// eslint-disable-next-line no-unused-vars
import tw from '../tw.scss';
import I18nProvider from './components/I18nProvider/I18nProvider';
import { setupBraze } from './helpers/braze';
import { getPromises } from './helpers/fetchdata';
import { setHistory } from './helpers/navigation';
import routes from './routes';
import * as authActions from './store/auth/actions';
import * as draftActions from './store/draft/actions';
import * as paymentActions from './store/payment/actions';
import i18next from './translations/i18n/i18next-client';

i18next.addResourceBundle(
  window.__i18nextInit.lng,
  'translation',
  window.__i18nextInit.resourceBundle
);

const clientDebug = debug('app:client');
const rootElement = document.getElementById('app');

if (process.env.NODE_ENV !== 'production') {
  window.reduxDebug = debug;
  window.reduxDebug.enable('*'); //this should be activated only on development env
}

store.dispatch(draftActions.loadFromLocalStorage());
store.dispatch(paymentActions.loadStripeInstance());
store.dispatch(authActions.authenticated(window.__currentUser));

clientDebug('rehydrating app');

function handleLocationChange(location, action) {
  getPromises({ url: location.pathname, store, routes, history });

  const isBackOrForwardsNavigation = action === 'POP';

  //timeout required to prevent scrollTo action from firing before chrome scrollTo event is ready
  if (!isBackOrForwardsNavigation) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
}

history.listen(handleLocationChange);

setHistory(history);

const state = store.getState();
const userId = state.auth?.currentUser?.id;

// Initialize Google Analytics
const brandName = state.localisation?.brandConfig?.name;
const gtagId = state.config.analytics.gtagId?.[brandName];
if (gtagId) {
  // eslint-disable-next-line camelcase
  ReactGA.initialize(gtagId, { gtagOptions: { user_id: userId } });
}

// Initialise Braze
if (inNativeApp()) {
  disableSDK();
} else {
  setupBraze({
    apiKey: state.config.braze.apiKey,
    baseUrl: state.config.braze.baseUrl,
    userId
  });
}

// eslint-disable-next-line camelcase, no-undef
__webpack_public_path__ = state.config.assetsPath;

const endpoint = state.config.endpoints.bff;

getPromises({ url: location.pathname, store, routes, history });

const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store} serverState={window.__INITIAL_STATE__}>
    <BffProvider endpoint={endpoint}>
      <I18nProvider i18n={i18next}>
        <DesignSystemProvider theme={joraTheme}>
          <Router history={history}>
            <Switch>
              <RouteMap routes={routes} />
            </Switch>
          </Router>
        </DesignSystemProvider>
      </I18nProvider>
    </BffProvider>
  </Provider>
);
