import { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

import { CardDetailsInput } from '@common/components/FormElements/CardDetailsInput';
import Label from '../../../../common/components/Label/Label';
import ReduxFormField from '../../../../common/components/FormElements/ReduxFormField/ReduxFormField';

@ReduxFormField
export default class CardFieldInput extends Component {
  static propTypes = {
    fieldId: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.any,
    touched: PropTypes.bool,
    hideLabel: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onReady: PropTypes.func.isRequired,
    'data-test-key': PropTypes.string
  };

  onChange(cardDetails) {
    const { onChange } = this.props;

    if (onChange) {
      onChange(cardDetails);
    }
  }

  render() {
    const { fieldId, error, label, hideLabel, 'data-test-key': dataTestKey, touched, onReady } = this.props;
    const hasError = error && touched;

    return (
      <View testID={dataTestKey || fieldId}>
        {hideLabel ? null : <Label>{label}</Label>}
        <CardDetailsInput
          accessibilityLabel={label}
          tone={hasError ? 'danger' : 'neutral'}
          onChange={(cardDetails) => this.onChange(cardDetails)}
          onReady={onReady}
        />
      </View>
    );
  }
}