import type { T } from '@src/caja/Form/types';
import { CompleteRegisterFormData } from '../types';

/**
 * Returns the validation rules for password confirmation.
 * @param t - The translation function.
 * @returns An object containing the validation rules.
 */
export function passwordConfirmationRules(t: T) {
  return {
    required: t('validations.general.password.required'),
    validate: (
      value: string,
      formFields: Partial<CompleteRegisterFormData>
    ) => {
      if (value !== formFields.password) {
        return t('validations.general.password.mismatch');
      }
      return;
    }
  };
}
