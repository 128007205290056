import { useEffect } from 'react';
import { ApplicationFilterValues } from '../../../../types';
import { useTrackFilters } from './hooks/useTrackFilters';

type UseAdvancedFiltersModalProps = {
  applySelectedFilters: () => void;
  clearAllFilters: () => void;
  closeModal: () => void;
  selectedApplicationsTab: 'matched' | 'other';
  isModalVisible: boolean;
  filterForm: ApplicationFilterValues;
};

export const useAdvancedFiltersModal = ({
  closeModal,
  isModalVisible,
  selectedApplicationsTab,
  filterForm,
  applySelectedFilters,
  clearAllFilters
}: UseAdvancedFiltersModalProps) => {
  const trackingEvents = useTrackFilters({
    userIsViewingApplicantsType: selectedApplicationsTab,
    filters: filterForm
  });

  useTrackModalOpened(isModalVisible, trackingEvents.trackModalOpened);

  const onApplyButtonClicked = () => {
    applySelectedFilters();
    trackingEvents.trackFiltersApplied();
    closeModal();
  };

  const onClearAllButtonClicked = () => {
    clearAllFilters();
    sendClearAllFiltersTrackingEvent('Application filters cleared');
    closeModal();
  };

  const sendClearAllFiltersTrackingEvent = (eventType: string) => {
    trackingEvents.trackFiltersCleared(eventType);
  };

  return {
    onApplyButtonClicked,
    onClearAllButtonClicked,
    sendClearAllFiltersTrackingEvent
  };
};

const useTrackModalOpened = (
  visible: boolean,
  trackModalOpened: () => void
) => {
  useEffect(
    () => {
      visible && trackModalOpened();
    },
    [visible] /* eslint-disable-line react-hooks/exhaustive-deps */
  );
};
