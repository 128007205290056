import React from 'react';
import { View } from 'react-native';
import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { useScrollableList } from '@components/ScrollableListProvider';
import { Stack } from '@components/Stack';
import { ListItemCard } from './ListItemCard';

export const ListBottomComponent = (props: {
  componentRef: React.MutableRefObject<View | null>;
}) => {
  const { scrollToTop } = useScrollableList();

  return (
    <Stack spacing="medium" tone="transparent">
      <ListItemCard title="1" />
      <ListItemCard title="2" />
      <ListItemCard title="3" />
      <ListItemCard title="4" />
      <ListItemCard title="4" />
      <View ref={props.componentRef} />
      <ListItemCard title="4 This is what it scrolls to" />
      <ListItemCard title="5" />

      <ListItemCard title="6" />
      <ListItemCard title="6" />
      <ListItemCard title="6" />
      <ListItemCard title="7" />
      <Box padding="large">
        <Button onPress={scrollToTop}>Scroll to top</Button>
      </Box>
    </Stack>
  );
};
