import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';
import { useScrollableTabList } from '@common/components/ScrollableTabListProvider';
import { Button } from '@components/Button';
import { Stack } from '@components/Stack';

type Props = PropsWithChildren<{
  componentRef: React.MutableRefObject<View | null>;
}>;
export const ListHeaderComponent = (props: Props) => {
  const { children, componentRef } = props;
  const { scrollToBottom, scrollToComponent, scrollToIndex } =
    useScrollableTabList();

  return (
    <Stack tone="transparent" spacing="medium">
      {children}
      <Button
        onPress={() => {
          scrollToBottom();
          console.log('scroll to bottom');
        }}
      >
        Scroll to bottom
      </Button>
      <Button
        onPress={() => {
          scrollToComponent(componentRef);
          console.log('scroll to Ref component');
        }}
      >
        Scroll to Ref component
      </Button>
      <Button
        onPress={() => {
          scrollToIndex(4);
          console.log('srcoll to 5th list item');
        }}
      >
        Scroll to 5th list item
      </Button>
    </Stack>
  );
};
