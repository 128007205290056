import React, { ComponentType, ReactElement, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { PaymentContext } from './PaymentContext';
import type { NativeStripe, StripeElements, WebStripe } from './components';

export type PaymentProviderProps = {
  children: ReactElement | null;
};

export const PaymentProvider = ({ children }: PaymentProviderProps) => {
  const [StripePaymentProvider, setStripePaymentProvider] =
    useState<ComponentType<PaymentProviderProps>>();
  const [stripe, setStripe] = useState<NativeStripe | WebStripe>();
  const [paymentIntentId, setPaymentIntentId] = useState<string>();
  const [clientId, setClientId] = useState<string>();
  const [elements, setElements] = useState<StripeElements | null>();

  const paymentContextData = {
    paymentIntentId,
    setPaymentIntentId,
    clientId,
    setClientId,
    stripe,
    setStripe,
    elements,
    setElements
  };

  useEffect(() => {
    (async () => {
      const module =
        Platform.OS === 'web'
          ? await import('./components/StripePaymentProvider.web')
          : await import('./components/StripePaymentProvider.native');
      setStripePaymentProvider(() => module.StripePaymentProvider);
    })();
  }, []);

  return StripePaymentProvider ? (
    <PaymentContext.Provider value={paymentContextData}>
      <StripePaymentProvider>
        {clientId ? children : null}
      </StripePaymentProvider>
    </PaymentContext.Provider>
  ) : null;
};
