import { ApplicationStatus } from '@common/types';

export enum FILTERS {
  APPLICANT_STATUS = 'applicant_status',
  VIDEO_SCREENING = 'video_screening',
  SORT_APPLICATIONS = 'sort_applications'
}

export enum SORT {
  APPLICATION_DATE = 'applicationDate',
  DISTANCE = 'distance',
  RIGHT_TO_WORK = 'rightToWork'
}

export type ApplicationSortValue =
  | SORT.APPLICATION_DATE
  | SORT.DISTANCE
  | SORT.RIGHT_TO_WORK;

export type ApplicationFilterValues = {
  [FILTERS.APPLICANT_STATUS]?: ApplicationStatus[];
  [FILTERS.VIDEO_SCREENING]?: boolean;
  [FILTERS.SORT_APPLICATIONS]: ApplicationSortValue;
};
