import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Button';
import { Divider } from '@components/Divider';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { TextLink } from '@components/TextLink';

type FooterActionsProps = {
  clearAllFilters: () => void;
  applyFilters: () => void;
};

export const FooterActions = (props: FooterActionsProps) => {
  const { t } = useTranslation();
  const { clearAllFilters, applyFilters } = props;

  return (
    <Stack tone="neutral">
      <Divider />
      <Row
        spacing="small"
        paddingHorizontal={{
          mobile: 'large',
          tablet: 'xlarge',
          desktop: 'xlarge'
        }}
        paddingVertical="medium"
        alignItems="center"
        justifyContent="space-between"
        tone="transparent"
      >
        <TextLink
          variant="secondary"
          onPress={clearAllFilters}
          testID="clear-advance-filter-button"
        >
          {t('applications.applicationsFilter.clearAll')}
        </TextLink>
        <Button onPress={applyFilters} testID="apply-advance-filter-button">
          {t('applications.applicationsFilter.apply')}
        </Button>
      </Row>
    </Stack>
  );
};
