import React from 'react';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { Box } from '@components/Box';
import { Field, Input } from '@components/FormElements';
import { Stack } from '@components/Stack';
import { T } from '../../types';

type ConfirmEmailProps = {
  value: string;
  onChange: () => void;
  onBlur: () => void;
  error?: string;
  t: T;
};

export const ConfirmEmail = ({
  error,
  onChange,
  onBlur,
  value,
  t
}: ConfirmEmailProps) => {
  return (
    <Stack spacing="small">
      <Field
        label={t('common.fieldLabel.confirmEmail')}
        message={
          error && (
            <Box marginTop="xxsmall">
              <ErrorMessage>{error}</ErrorMessage>
            </Box>
          )
        }
      >
        <Input
          id="account-details-form-confirm-email"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      </Field>
    </Stack>
  );
};
