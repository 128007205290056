import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@components/Column';
import { RadioButton } from '@components/FormElements';
import { Row } from '@components/Row';
import { Stack } from '@components/Stack';
import { Text } from '@components/Text';
import { ApplicationSortValue } from '../../../../../../types';
import { useSortApplicationsOptions } from './useSortApplicationsOptions';

type SortApplicationsProps = {
  value: ApplicationSortValue;
  onChange: (selectedValue: ApplicationSortValue) => void;
};

export const SortApplications = ({
  onChange,
  value
}: SortApplicationsProps) => {
  const { t } = useTranslation();
  const options = useSortApplicationsOptions();

  const firstColumnOptions = options.slice(0, 2);
  const secondColumnOptions = options.slice(2);

  return (
    <Column spacing="large">
      <Row justifyContent="space-between" alignItems="center">
        <Text weight="medium">{t('applications.sortingDropdown.sortBy')}</Text>
      </Row>

      <Stack
        spacing="medium"
        direction={{ desktop: 'row', tablet: 'column', mobile: 'column' }}
      >
        <OptionsColumn
          options={firstColumnOptions}
          value={value}
          onChange={onChange}
        />

        <OptionsColumn
          options={secondColumnOptions}
          value={value}
          onChange={onChange}
        />
      </Stack>
    </Column>
  );
};

type OptionsColumnProps = SortApplicationsProps & {
  options: ReturnType<typeof useSortApplicationsOptions>;
};

const OptionsColumn = ({ options, value, onChange }: OptionsColumnProps) => {
  const handleRadioButton = (key: ApplicationSortValue) => {
    if (value === key) {
      return;
    }
    onChange(key);
  };

  return (
    <Column
      spacing="medium"
      width={{
        desktop: '1/2',
        tablet: 'full',
        mobile: 'full'
      }}
    >
      {options.map(({ key, label }) => (
        <RadioButton
          key={key}
          checked={value === key}
          label={label}
          testID={`sort-application-option-${key}`}
          onPress={() => handleRadioButton(key)}
        />
      ))}
    </Column>
  );
};
