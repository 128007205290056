import React from 'react';
import { CookieConsent as InnerField } from '@common/components/Register/components/CookieConsent';
import { FieldWrapper } from '../FieldWrapper';

export function CookieConsent() {
  return (
    <FieldWrapper<boolean>
      name="cookieConsent"
      render={(props) => <InnerField {...props} />}
    />
  );
}
