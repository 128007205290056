import React, { useState } from 'react';
import {
  ItemData,
  ScrollableListProvider
} from '@components/ScrollableListProvider';
import { Stack } from '@components/Stack';
import { Tab, TabList } from '@components/Tabs';
import { Props } from './types';

export const ScrollableTabListProvider = <T extends ItemData>(
  props: Props<T>
) => {
  const {
    ListHeaderComponent: HeaderComponent,
    tabOptions,
    onTabPress
  } = props;

  const [selectedTab, setSelectedTab] = useState(0);
  const selectedData = tabOptions[selectedTab].data || [];

  const ListHeaderComponent = (
    <Stack spacing="medium" marginBottom="medium" tone="transparent">
      {HeaderComponent}
      <TabList>
        {tabOptions.map((tabOption, index) => (
          <Tab
            key={index}
            isSelected={selectedTab === index}
            handleOnPress={() => {
              setSelectedTab(index);

              if (!onTabPress) return;

              onTabPress({ tabId: tabOption.id, tabIndex: index });
            }}
          >
            {tabOption.label}
          </Tab>
        ))}
      </TabList>
    </Stack>
  );

  return (
    <ScrollableListProvider
      {...props}
      data={selectedData}
      ListHeaderComponent={ListHeaderComponent}
    />
  );
};
