import React from 'react';
import { FlatList, FlatListProps } from 'react-native';
import { ScrollableListContext } from './ScrollableListContext';
import {
  scrollToBottomOfList,
  scrollToComponentInList,
  scrollToItemIndex,
  scrollToTopOfList
} from './lib';
import { ItemData, ScrollableListProps } from './types';

export type Props<T> = ScrollableListProps<T> & {
  refreshControl?: FlatListProps<T>['refreshControl'];
};

/**
 * @description Use this component to wrap your list content that needs to be scrollable.
 * It provides a context for scrolling to the top or bottom or component or index of a list.
 * It also uses React native FlatList component to provide a scrollable list.
 * If you need to scroll through a view (not a list), use `ScrollViewProvider`.
 */
export const ScrollableListProvider = <T extends ItemData>(props: Props<T>) => {
  const { refreshControl, ...restProps } = props;
  const listViewRef = React.useRef<FlatList | null>(null);

  return (
    <ScrollableListContext.Provider
      value={{
        scrollToTop: () => scrollToTopOfList(listViewRef),
        scrollToBottom: () => scrollToBottomOfList(listViewRef),
        scrollToComponent: (targetViewRef) =>
          scrollToComponentInList(listViewRef, targetViewRef),
        scrollToIndex: (index) => scrollToItemIndex(listViewRef, index)
      }}
    >
      <FlatList
        ref={listViewRef}
        keyExtractor={({ id }) => id}
        refreshControl={refreshControl}
        {...restProps}
      />
    </ScrollableListContext.Provider>
  );
};
