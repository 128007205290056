import React, { ComponentType, useEffect, useState } from 'react';
import { AccessibilityProps, Platform } from 'react-native';
import type {
  CardFieldInput,
  StripePaymentElementChangeEvent
} from './components';

export type CardDetailsInputProps = {
  accessibilityLabel: AccessibilityProps['accessibilityLabel'];
  onChange: (
    cardDetails: CardFieldInput.Details | StripePaymentElementChangeEvent
  ) => void;
  onReady: () => void;
  tone: 'neutral' | 'danger';
};

export const CardDetailsInput = (props: CardDetailsInputProps) => {
  const { accessibilityLabel, tone, onChange, onReady } = props;
  const [StripeCardDetailsInput, setStripeCardDetailsInput] =
    useState<ComponentType<CardDetailsInputProps>>();

  useEffect(() => {
    (async () => {
      const module =
        Platform.OS === 'web'
          ? await import('./components/StripeCardDetailsInput.web')
          : await import('./components/StripeCardDetailsInput.native');
      setStripeCardDetailsInput(() => module.StripeCardDetailsInput);
    })();
  }, []);

  return StripeCardDetailsInput ? (
    <StripeCardDetailsInput
      accessibilityLabel={accessibilityLabel}
      tone={tone}
      onChange={onChange}
      onReady={onReady}
    />
  ) : null;
};
