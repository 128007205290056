import React from 'react';
import { useTranslation } from 'react-i18next';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { LINK, useCandidateLink } from '@common/hooks/useCandidateLink';
import { useRedirectAuthenticatedUser } from '@common/hooks/useRedirectAuthenticatedUser';
import { Box } from '@components/Box';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { CandidateLogin } from './components/CandidateLogin';
import { LoginForm } from './components/LoginForm/LoginForm';

export const Login = () => {
  const { t } = useTranslation();

  useRedirectAuthenticatedUser();
  useCandidateLink();

  const { getCandidateLink } = useCandidateLink();
  const candidateLogin = getCandidateLink(LINK.LOGIN) as string;
  const candidateRegister = getCandidateLink(LINK.REGISTRATION) as string;

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={t('loginPage.title')} />

      <Stack spacing="medium" tone="transparent">
        <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
          <Heading level="h1">{t('loginPage.heading')}</Heading>
        </Box>

        <Card>
          <CardContent>
            <LoginForm />
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <CandidateLogin
              candidateLogin={candidateLogin}
              candidateRegister={candidateRegister}
            />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};
