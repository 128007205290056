import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@components/Column';
import { Checkbox } from '@components/FormElements';
import { Row } from '@components/Row';
import { Text } from '@components/Text';
import { TextLink } from '@components/TextLink';
import { useVideoScreeningOptions } from './useVideoScreeningOptions';

type VideoScreeningFilterProps = {
  value?: boolean;
  onClear: () => void;
  onChange: (selectedValue: boolean) => void;
};

export const VideoScreeningFilter = ({
  onChange,
  onClear,
  value = false
}: VideoScreeningFilterProps) => {
  const { t } = useTranslation();
  const options = useVideoScreeningOptions();

  return (
    <Column spacing="large">
      <Row justifyContent="space-between" alignItems="center">
        <Text weight="medium">
          {t('applications.applicationsFilter.videoScreening.title')}
        </Text>

        <TextLink
          variant={value ? 'primary' : 'secondary'}
          onPress={onClear}
          testID="clear-advance-filter-button"
        >
          {t('applications.applicationsFilter.clear')}
        </TextLink>
      </Row>

      <Options
        options={options}
        value={value}
        onChange={onChange}
        onClear={onClear}
      />
    </Column>
  );
};

type OptionsProps = VideoScreeningFilterProps & {
  options: ReturnType<typeof useVideoScreeningOptions>;
};

const Options = ({
  options,
  value = false,
  onChange,
  onClear
}: OptionsProps) => {
  const handleCheckbox = (checkboxValue: boolean) => {
    if (value === checkboxValue) {
      return onClear();
    }

    return onChange(checkboxValue);
  };

  return (
    <Row spacing="medium">
      {options.map(({ key, label, value: checkboxValue }) => (
        <Checkbox
          key={key}
          value={value === checkboxValue}
          label={label}
          testID={`video-screening-filter-option-${key}`}
          onChange={() => handleCheckbox(checkboxValue)}
        />
      ))}
    </Row>
  );
};
