import { useState } from 'react';
import { SingleSelectProps } from './SingleSelect';

type UseSingleSelectProps = Pick<SingleSelectProps, 'value' | 'onChange'>;

export const useSingleSelect = ({ value, onChange }: UseSingleSelectProps) => {
  const [selectedKey, setSelectedKey] = useState(value);

  const updateSelectedKey = (key: string) => {
    setSelectedKey(() => {
      onChange(key);
      return key;
    });
  };

  return {
    selectedKey,
    updateSelectedKey
  };
};
