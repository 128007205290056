import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, getFormValues } from 'redux-form';
import { Dropdown } from '@common/components/FormElements/Dropdown';
import { InputField } from '@common/components/FormElements/InputField';
import { useAppSelector } from '@store/hooks';
import * as constants from '@store/payment/constants';
import { Box } from '@components/Box';
import { Text } from '@components/Text';

type BankTransferInputProps = {
  onReady: () => void;
};

export type BankTransferFormValues = {
  bankName: string;
  country: string;
  email: string;
  fullName: string;
  paymentMethod: string;
};

export const IndonesianBankTransferInput = ({
  onReady
}: BankTransferInputProps) => {
  const { t } = useTranslation();
  const currentValues = useAppSelector(
    getFormValues(constants.CONFIRM_PAYMENT_FORM)
  );
  const selectedBankName = (currentValues as BankTransferFormValues)?.bankName;

  const getBankTransferTimeText = () => {
    return selectedBankName
      ? t(
          `checkoutForm.bankTransfer.bankDetails.${selectedBankName}.transferTime`
        )
      : null;
  };

  const getBankOptions = () => {
    return ['noBank', 'bca', 'bni', 'mandiri', 'other'].map((bank) => {
      if (bank === 'noBank') {
        return {
          key: bank,
          label: t('checkoutForm.bankTransfer.select'),
          value: ''
        };
      }
      return {
        key: bank,
        label: t(`checkoutForm.bankTransfer.bankDetails.${bank}.name`),
        value: bank
      };
    });
  };

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <>
      <Field
        component={InputField}
        name="fullName"
        fieldId="fullName"
        label={t('checkoutForm.bankTransfer.fieldLabel.fullName')}
        placeholder={t('checkoutForm.bankTransfer.fieldLabel.firstAndLastName')}
        autoCorrect="off"
        autoCapitalize="words"
        validationMessages={t('validations.user.fullName', {
          returnObjects: true
        })}
        longField
      />
      <Field
        component={Dropdown}
        name="bankName"
        fieldId="bankName"
        label={t('checkoutForm.bankTransfer.fieldLabel.selectBank')}
        validationMessages={t('validations.payment.bankName', {
          returnObjects: true
        })}
        options={getBankOptions()}
      />

      {selectedBankName && (
        <Box marginTop="small">
          <Text size="xsmall" tone="neutralLight">
            {getBankTransferTimeText()}
          </Text>
        </Box>
      )}

      <Box marginTop="xlarge">
        <Text size="small" tone="neutralLight">
          {t('checkoutForm.bankTransfer.description')}
        </Text>
      </Box>
    </>
  );
};
