import React from 'react';
import { TextInput } from 'react-native';
import { Card, CardContent } from '@components/Card';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';

export const ListItemCard = ({ title }: { title: string }) => {
  return (
    <Card>
      <CardContent>
        <Stack spacing="medium">
          <Heading level="h2">{'List item ' + title}</Heading>
          <TextInput
            style={{ borderWidth: 1, padding: 10, borderColor: 'green' }}
            placeholder="This is a text field"
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
