import React from 'react';
import { JoraEmployerHelmet } from '@common/components/Helmet';
import { useDisableShellScrollViews } from '@src/joraEmployer/components/JoraEmployerShell/components/ScrollViewContext';
import { Box } from '@components/Box';
import { Heading } from '@components/Heading';
import { Stack } from '@components/Stack';
import { FlatListWithTabsExample } from './ScrollableExamples/FlatListWithTabsExample';
import { UpgradeJobTestWidget } from './UpgradeJobTestWidget';

export const Playground = () => {
  useDisableShellScrollViews();

  return (
    <Stack tone="transparent">
      <JoraEmployerHelmet title={'Playground'} />
      <FlatListWithTabsExample>
        <Stack tone="transparent" spacing="medium" paddingBottom="xlarge">
          <Box paddingTop="medium" paddingLeft="medium" tone="transparent">
            <Heading level="h1">Welcome to the Playground</Heading>
          </Box>

          <UpgradeJobTestWidget />
        </Stack>
      </FlatListWithTabsExample>
    </Stack>
  );
};
