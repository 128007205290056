import { useTheme } from '@theme/DesignSystemProvider/ThemeContext';
import { useResolveToken } from '@theme/tokens/utils/useResolveToken';

export type RadioButtonStyleProps = {
  disabled?: boolean;
  checked?: boolean;
  focused?: boolean;
  radioButtonAlign?: 'left' | 'right';
};

export function useRadioButtonStyle({
  disabled,
  checked,
  focused,
  radioButtonAlign = 'left'
}: RadioButtonStyleProps) {
  const {
    border,
    radioButton,
    icon,
    typography: { fontWeights, fontFamily },
    spacing
  } = useTheme();
  const { resolveToken } = useResolveToken();

  const mergedStyle = { ...resolveToken(radioButton.state, 'default') };

  if (checked) {
    const colorName = disabled ? 'disabled' : 'checked';
    mergedStyle.backgroundColor = resolveToken(radioButton.color, colorName);
  }

  if (disabled) {
    const disabledColor = resolveToken(radioButton.color, 'disabled');
    mergedStyle.fontColor = disabledColor;
    mergedStyle.borderColor = disabledColor;
  } else if (focused) {
    mergedStyle.borderColor = resolveToken(radioButton.color, 'focused');
  }

  const { backgroundColor, borderColor, fontColor } = mergedStyle;

  return {
    innerStyle: {
      backgroundColor,
      borderRadius: resolveToken(border.radius, 'xlarge'),
      width: resolveToken(icon.sizes, 'xxxsmall'),
      height: resolveToken(icon.sizes, 'xxxsmall'),
      transition:
        checked &&
        'background-color 0.2s, transform .2s cubic-bezier(0.020, 1.505, 0.745, 1.235)'
    },
    outerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: borderColor,
      borderRadius: resolveToken(border.radius, 'xlarge'),
      borderWidth: resolveToken(border.width, 'xsmall'),
      width: resolveToken(icon.sizes, 'medium'),
      height: resolveToken(icon.sizes, 'medium'),
      marginLeft: resolveToken(
        spacing,
        radioButtonAlign === 'right' ? 'xxsmall' : 'none'
      ),
      marginRight: resolveToken(
        spacing,
        radioButtonAlign === 'left' ? 'small' : 'none'
      )
    },
    pressableStyle: {
      borderWidth: resolveToken(border.width, 'none'),
      outlineWidth: 0,
      flexDirection: 'row',
      justifyContent:
        radioButtonAlign === 'right' ? 'space-between' : undefined,
      alignItems: 'center'
    },
    textStyle: {
      color: fontColor,
      fontWeight: resolveToken(fontWeights, 'light'),
      fontFamily
    }
  } as const;
}
