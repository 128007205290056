import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from '@components/Column';
import { Divider } from '@components/Divider';
import { Modal } from '@components/Modal';
import { ApplicationFilterValues, FILTERS } from '../../../../types';
import {
  ApplicantStatusFilter,
  FooterActions,
  VideoScreeningFilter
} from './components';
import { SortApplications } from './components/SortApplications';
import { useApplySelectedFilters } from './hooks/useApplySelectedFilters';
import { useFilterForm } from './hooks/useFilterForm';
import { useAdvancedFiltersModal } from './useAdvancedFiltersModal';

type AdvancedFiltersModalProps = {
  setApplicationFilters: (filter: ApplicationFilterValues) => void;
  onRequestClose: () => void;
  selectedApplicationsTab: 'matched' | 'other';
  visible: boolean;
};

export const AdvancedFiltersModal = forwardRef(
  (props: AdvancedFiltersModalProps, ref) => {
    const { t } = useTranslation();
    const {
      visible,
      onRequestClose,
      setApplicationFilters,
      selectedApplicationsTab
    } = props;

    const { filterForm, setFilterForm, handleFieldChange, handleFieldClear } =
      useFilterForm();

    const { applySelectedFilters, clearAllFilters } = useApplySelectedFilters({
      filterForm,
      setApplicationFilters,
      setFilterForm
    });

    const {
      onApplyButtonClicked,
      onClearAllButtonClicked,
      sendClearAllFiltersTrackingEvent
    } = useAdvancedFiltersModal({
      filterForm,
      applySelectedFilters,
      clearAllFilters,
      closeModal: onRequestClose,
      selectedApplicationsTab,
      isModalVisible: visible
    });

    useImperativeHandle(ref, () => ({
      clearAllFilters,
      sendClearAllFiltersTrackingEvent
    }));

    return (
      <Modal
        visible={visible}
        title={t('applications.applicationsFilter.action.filter')}
        height="fit-content"
        testID="applications-advance-filter-modal"
        onRequestClose={onRequestClose}
        footerChildren={
          <FooterActions
            clearAllFilters={onClearAllButtonClicked}
            applyFilters={onApplyButtonClicked}
          />
        }
      >
        <Column spacing="large">
          <SortApplications
            value={filterForm[FILTERS.SORT_APPLICATIONS]}
            onChange={handleFieldChange(FILTERS.SORT_APPLICATIONS)}
          />

          <Divider />

          <ApplicantStatusFilter
            value={filterForm[FILTERS.APPLICANT_STATUS]}
            onChange={handleFieldChange(FILTERS.APPLICANT_STATUS)}
            onClear={handleFieldClear(FILTERS.APPLICANT_STATUS)}
          />

          <Divider />

          <VideoScreeningFilter
            value={filterForm[FILTERS.VIDEO_SCREENING]}
            onChange={handleFieldChange(FILTERS.VIDEO_SCREENING)}
            onClear={handleFieldClear(FILTERS.VIDEO_SCREENING)}
          />
        </Column>
      </Modal>
    );
  }
);
