import React from 'react';
import { FlatList } from 'react-native';
import { ProfileApplication } from 'je-bff/src/applications/list/transformers';
import { NPS } from '@common/components/NPS';
import { useUpdateNote } from '@common/hooks/application/notes/useUpdateNote';
import { Stack } from '@components/Stack';
import { ApplicationCard } from '../ApplicationCard';
import { ApplicationContext } from './ApplicationContext';
import { useApplicationList, useNps } from './useApplicationList';

type ApplicationListProps = {
  jobId: string;
  applications: ProfileApplication[];
  testID?: string;
};

export const ApplicationList = (props: ApplicationListProps) => {
  const { jobId, applications, testID } = props;
  const { jobCountryCode } = useApplicationList(jobId);
  const { shouldRenderNPS, npsTrackingData } = useNps(jobId);
  const updateNote = useUpdateNote({ jobId });

  return (
    <ApplicationContext.Provider value={{ updateNote }}>
      <Stack tone="transparent" testID={testID}>
        <FlatList
          data={applications}
          renderItem={({ item: application, index }) => {
            return (
              <Stack key={`${application.id}-card`} tone="transparent">
                <ApplicationCard
                  jobId={jobId}
                  jobCountryCode={jobCountryCode}
                  application={application}
                />

                {shouldRenderNPS(index) ? (
                  <NPS trackingData={npsTrackingData} />
                ) : null}
              </Stack>
            );
          }}
        />
      </Stack>
    </ApplicationContext.Provider>
  );
};
