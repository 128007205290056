import { useGetUserPackage } from '@common/hooks/userPackages';
import { ApplicationStatus } from '@common/types';
import {
  trackApplicationFiltersApplied,
  trackApplicationFiltersModalCleared,
  trackApplicationFiltersModalOpened
} from '@store/application_filters';
import { useAppDispatch } from '@store/hooks';
import { ApplicationSortValue, FILTERS } from '../../../../../types';

type UseTrackFiltersType = {
  userIsViewingApplicantsType: 'matched' | 'other';
  filters: {
    [FILTERS.APPLICANT_STATUS]?: ApplicationStatus[];
    [FILTERS.VIDEO_SCREENING]?: boolean;
    [FILTERS.SORT_APPLICATIONS]: ApplicationSortValue;
  };
};

export function useTrackFilters({
  userIsViewingApplicantsType,
  filters
}: UseTrackFiltersType) {
  const dispatch = useAppDispatch();
  const userPackage = useGetUserPackage();

  return {
    trackModalOpened: () =>
      dispatch(
        trackApplicationFiltersModalOpened({
          userIsViewingApplicantsType
        })
      ),

    trackFiltersCleared: (eventType: string) =>
      dispatch(trackApplicationFiltersModalCleared(eventType)),

    trackFiltersApplied: () =>
      dispatch(
        trackApplicationFiltersApplied({
          userPackageType: userPackage.packageType || 'free',
          userHasPremiumFilters: false, // TODO: when UI is premium-filter-aware, come back to update this
          applicantStatusFilter: filters.applicant_status,
          videoScreeningFilter: filters.video_screening,
          sortBy: filters.sort_applications
        })
      )
  };
}
