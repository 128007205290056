import {
  APPLICANT_STATUS,
  BUSINESS_ROLE,
  BUSINESS_SIZE,
  INDUSTRY
} from '@seek/je-shared-data';
import { URL_REGEX } from '@seek/je-shared-data/lib/regex';
import { BrandName } from '@seek/je-shared-data/lib/types/brand';
import { SORT } from '../pages/job/details/components/JobApplicationDetails/types';
import i18next from '../translations/i18n/i18next-server';
import { EMAIL_VERIFICATION_CODE_LENGTH } from './../../common/constants/validation';

export const REGEX = {
  MOBILE_FORMAT: /\d{6}/,
  CONTAINS_EMAIL: /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/,
  EMAIL_FORMAT: /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/,
  NON_ALPHANUMERIC_REMOVAL_FORMAT: /[\W_]/g,
  PASSWORD: /^(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/,
  JOB_ID:
    /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i,
  WEBSITE_FORMAT: URL_REGEX,
  ANGLE_BRACKET: /(<|>)/,
  CONTAINS_ANGLE_BRACKET: /^[^<>%$]*$/,
  SPACES: /\s/g,
  VALID_DATE:
    /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.][2-9]\d\d\d$/,
  EMAIL_VERIFICATION_CODE: new RegExp(
    `^[0-9]{${EMAIL_VERIFICATION_CODE_LENGTH}}$`
  ),
  HTML: /<[^>]+>/g,
  NON_BREAKING_SPACE: /&nbsp;/g
};

// tags whitelisted by the candi Job Service API
export const ALLOWED_HTML_TAGS = ['div', 'p', 'ul', 'ol', 'li', 'strong'];
export const NO_STATUS = 'noStatus';
// this will override id translation to en
const enT = i18next.getFixedT('en');

export const EMPLOYMENT_TYPE_OPTIONS = (t, brandName) => {
  return [
    {
      key: 'noJobType',
      label: t('postJobForm.jobType.selectJobType'),
      value: ''
    },
    {
      key: 'full_time',
      label:
        brandName === BrandName.JOBSTREETEXPRESS
          ? enT('postJobForm.jobType.full_time')
          : t('postJobForm.jobType.full_time'),
      value: 'full_time'
    },
    {
      key: 'part_time',
      label:
        brandName === BrandName.JOBSTREETEXPRESS
          ? enT('postJobForm.jobType.part_time')
          : t('postJobForm.jobType.part_time'),
      value: 'part_time'
    },
    {
      key: 'casual',
      label:
        brandName === BrandName.JOBSTREETEXPRESS
          ? enT('postJobForm.jobType.casual')
          : t('postJobForm.jobType.casual'),
      value: 'casual'
    }
  ];
};

export const SORT_APPLICATIONS_OPTIONS = (t: (key: string) => string) =>
  [
    {
      key: SORT.APPLICATION_DATE,
      label: t('applications.sortingDropdown.options.applicationDate'),
      value: SORT.APPLICATION_DATE
    },
    {
      key: SORT.RIGHT_TO_WORK,
      label: t('applications.sortingDropdown.options.rightToWork'),
      value: SORT.RIGHT_TO_WORK
    },
    {
      key: SORT.DISTANCE,
      label: t('applications.sortingDropdown.options.distance'),
      value: SORT.DISTANCE
    }
  ] as const;

export const APPLICATION_STATUS_FILTER_OPTIONS = (t: (key: string) => string) =>
  [
    {
      key: NO_STATUS,
      label: t('applications.status.noStatus'),
      value: NO_STATUS
    },
    {
      key: APPLICANT_STATUS.SHORTLISTED,
      label: t('applications.status.shortlisted'),
      value: APPLICANT_STATUS.SHORTLISTED
    },
    {
      key: APPLICANT_STATUS.SCHEDULED_INTERVIEW,
      label: t('applications.status.scheduledInterview'),
      value: APPLICANT_STATUS.SCHEDULED_INTERVIEW
    },
    {
      key: APPLICANT_STATUS.OFFERED_JOB,
      label: t('applications.status.offeredJob'),
      value: APPLICANT_STATUS.OFFERED_JOB
    },
    {
      key: APPLICANT_STATUS.ACCEPTED_OFFER,
      label: t('applications.status.acceptedOffer'),
      value: APPLICANT_STATUS.ACCEPTED_OFFER
    },
    {
      key: APPLICANT_STATUS.NOT_SUITABLE,
      label: t('applications.status.notSuitable'),
      value: APPLICANT_STATUS.NOT_SUITABLE
    }
  ] as const;

export const VIDEO_SCREENING_FILTER_OPTIONS = (t: (key: string) => string) =>
  [
    {
      key: 'hasVideoScreening',
      label: t('applications.applicationsFilter.videoScreening.option'),
      value: true
    }
  ] as const;

export const BUSINESS_ROLE_OPTIONS = (t) => [
  { key: 'noRole', label: t('business.businessRole.select'), value: '' },
  {
    key: BUSINESS_ROLE.OWNER,
    label: t('business.businessRole.owner'),
    value: BUSINESS_ROLE.OWNER
  },
  {
    key: BUSINESS_ROLE.EMPLOYEE,
    label: t('business.businessRole.employee'),
    value: BUSINESS_ROLE.EMPLOYEE
  },
  {
    key: BUSINESS_ROLE.RECRUITER,
    label: t('business.businessRole.recruiter'),
    value: BUSINESS_ROLE.RECRUITER
  }
];

export const BUSINESS_SIZE_OPTIONS = (t) => [
  {
    key: 'noSize',
    label: t('createBusinessForm.businessSize.select'),
    value: ''
  },
  {
    key: BUSINESS_SIZE.LESS_THAN_5_EMPLOYEES,
    label: t('createBusinessForm.businessSize.LESS_THAN_5_EMPLOYEES'),
    value: BUSINESS_SIZE.LESS_THAN_5_EMPLOYEES
  },
  {
    key: BUSINESS_SIZE.LESS_THAN_20_EMPLOYEES,
    label: t('createBusinessForm.businessSize.LESS_THAN_20_EMPLOYEES'),
    value: BUSINESS_SIZE.LESS_THAN_20_EMPLOYEES
  },
  {
    key: BUSINESS_SIZE.LESS_THAN_50_EMPLOYEES,
    label: t('createBusinessForm.businessSize.LESS_THAN_50_EMPLOYEES'),
    value: BUSINESS_SIZE.LESS_THAN_50_EMPLOYEES
  },
  {
    key: BUSINESS_SIZE.LESS_THAN_100_EMPLOYEES,
    label: t('createBusinessForm.businessSize.LESS_THAN_100_EMPLOYEES'),
    value: BUSINESS_SIZE.LESS_THAN_100_EMPLOYEES
  },
  {
    key: BUSINESS_SIZE.LESS_THAN_200_EMPLOYEES,
    label: t('createBusinessForm.businessSize.LESS_THAN_200_EMPLOYEES'),
    value: BUSINESS_SIZE.LESS_THAN_200_EMPLOYEES
  },
  {
    key: BUSINESS_SIZE.MORE_THAN_200_EMPLOYEES,
    label: t('createBusinessForm.businessSize.MORE_THAN_200_EMPLOYEES'),
    value: BUSINESS_SIZE.MORE_THAN_200_EMPLOYEES
  }
];

export const INDUSTRY_ID_TRANSLATE_MAPPER = {
  [INDUSTRY.FOOD_HOSPITALITY_TOURISM]:
    'business.industry.foodHospitalityTourism',
  [INDUSTRY.RETAIL_WHOLESALE]: 'business.industry.retailWholesale',
  [INDUSTRY.MEDIA_ADVERTISING_ONLINE]:
    'business.industry.mediaAdvertisingOnline',
  [INDUSTRY.TRANSPORT_LOGISTICS]: 'business.industry.transportLogistics',
  [INDUSTRY.MANUFACTURING]: 'business.industry.manufacturing',
  [INDUSTRY.CONSTRUCTION_DESIGN_ENGINEERING]:
    'business.industry.constructionDesignEngineering',
  [INDUSTRY.INFORMATION_COMMUNICATION_TECHNOLOGY]:
    'business.industry.informationCommunicationTechnology',
  [INDUSTRY.FINANCIAL_INSURANCE_SERVICES]:
    'business.industry.financialInsuranceServices',
  [INDUSTRY.REAL_ESTATE_PROPERTY_SERVICES]:
    'business.industry.realEstatePropertyServices',
  [INDUSTRY.PROFESSIONAL_SERVICES]: 'business.industry.professionalServices',
  [INDUSTRY.HEALTHCARE_SOCIAL_ASSISTANCE]:
    'business.industry.healthcareSocialAssistance',
  [INDUSTRY.EDUCATION_TRAINING_RESEARCH]:
    'business.industry.educationTrainingResearch',
  [INDUSTRY.PERSONAL_HOME_VEHICLE_SERVICES]:
    'business.industry.personalHomeVehicleServices',
  [INDUSTRY.EMPLOYMENT_OUTSOURCING_FACILITIES]:
    'business.industry.employmentOutsourcingFacilities',
  [INDUSTRY.PRIMARY_INDUSTRIES_METALS]:
    'business.industry.primaryIndustriesMetals',
  [INDUSTRY.ELECTRICITY_GAS_WATER_WASTE_SERVICES]:
    'business.industry.electricityGasWaterWasteServices',
  [INDUSTRY.GOVERNMENT_DEFENCE_PUBLIC_SAFETY]:
    'business.industry.governmentDefencePublicSafety',
  [INDUSTRY.OTHER]: 'business.industry.other'
};

export const INDUSTRY_OPTIONS = (t) => [
  {
    key: 'noIndustry',
    label: t('business.industry.select'),
    value: ''
  },
  {
    key: INDUSTRY.FOOD_HOSPITALITY_TOURISM,
    label: t(INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.FOOD_HOSPITALITY_TOURISM]),
    value: INDUSTRY.FOOD_HOSPITALITY_TOURISM
  },
  {
    key: INDUSTRY.RETAIL_WHOLESALE,
    label: t(INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.RETAIL_WHOLESALE]),
    value: INDUSTRY.RETAIL_WHOLESALE
  },
  {
    key: INDUSTRY.MEDIA_ADVERTISING_ONLINE,
    label: t(INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.MEDIA_ADVERTISING_ONLINE]),
    value: INDUSTRY.MEDIA_ADVERTISING_ONLINE
  },
  {
    key: INDUSTRY.TRANSPORT_LOGISTICS,
    label: t(INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.TRANSPORT_LOGISTICS]),
    value: INDUSTRY.TRANSPORT_LOGISTICS
  },
  {
    key: INDUSTRY.MANUFACTURING,
    label: t(INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.MANUFACTURING]),
    value: INDUSTRY.MANUFACTURING
  },
  {
    key: INDUSTRY.CONSTRUCTION_DESIGN_ENGINEERING,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.CONSTRUCTION_DESIGN_ENGINEERING]
    ),
    value: INDUSTRY.CONSTRUCTION_DESIGN_ENGINEERING
  },
  {
    key: INDUSTRY.INFORMATION_COMMUNICATION_TECHNOLOGY,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[
        INDUSTRY.INFORMATION_COMMUNICATION_TECHNOLOGY
      ]
    ),
    value: INDUSTRY.INFORMATION_COMMUNICATION_TECHNOLOGY
  },
  {
    key: INDUSTRY.FINANCIAL_INSURANCE_SERVICES,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.FINANCIAL_INSURANCE_SERVICES]
    ),
    value: INDUSTRY.FINANCIAL_INSURANCE_SERVICES
  },
  {
    key: INDUSTRY.REAL_ESTATE_PROPERTY_SERVICES,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.REAL_ESTATE_PROPERTY_SERVICES]
    ),
    value: INDUSTRY.REAL_ESTATE_PROPERTY_SERVICES
  },
  {
    key: INDUSTRY.PROFESSIONAL_SERVICES,
    label: t(INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.PROFESSIONAL_SERVICES]),
    value: INDUSTRY.PROFESSIONAL_SERVICES
  },
  {
    key: INDUSTRY.HEALTHCARE_SOCIAL_ASSISTANCE,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.HEALTHCARE_SOCIAL_ASSISTANCE]
    ),
    value: INDUSTRY.HEALTHCARE_SOCIAL_ASSISTANCE
  },
  {
    key: INDUSTRY.EDUCATION_TRAINING_RESEARCH,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.EDUCATION_TRAINING_RESEARCH]
    ),
    value: INDUSTRY.EDUCATION_TRAINING_RESEARCH
  },
  {
    key: INDUSTRY.PERSONAL_HOME_VEHICLE_SERVICES,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.PERSONAL_HOME_VEHICLE_SERVICES]
    ),
    value: INDUSTRY.PERSONAL_HOME_VEHICLE_SERVICES
  },
  {
    key: INDUSTRY.EMPLOYMENT_OUTSOURCING_FACILITIES,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.EMPLOYMENT_OUTSOURCING_FACILITIES]
    ),
    value: INDUSTRY.EMPLOYMENT_OUTSOURCING_FACILITIES
  },
  {
    key: INDUSTRY.PRIMARY_INDUSTRIES_METALS,
    label: t(INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.PRIMARY_INDUSTRIES_METALS]),
    value: INDUSTRY.PRIMARY_INDUSTRIES_METALS
  },
  {
    key: INDUSTRY.ELECTRICITY_GAS_WATER_WASTE_SERVICES,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[
        INDUSTRY.ELECTRICITY_GAS_WATER_WASTE_SERVICES
      ]
    ),
    value: INDUSTRY.ELECTRICITY_GAS_WATER_WASTE_SERVICES
  },
  {
    key: INDUSTRY.GOVERNMENT_DEFENCE_PUBLIC_SAFETY,
    label: t(
      INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.GOVERNMENT_DEFENCE_PUBLIC_SAFETY]
    ),
    value: INDUSTRY.GOVERNMENT_DEFENCE_PUBLIC_SAFETY
  },
  {
    key: INDUSTRY.OTHER,
    label: t(INDUSTRY_ID_TRANSLATE_MAPPER[INDUSTRY.OTHER]),
    value: INDUSTRY.OTHER
  }
];

export const LOCALISED_COUNTRIES = (t) => ({
  au: t('countries.australia'),
  br: t('countries.brazil'),
  hk: t('countries.hongKong'),
  id: t('countries.indonesia'),
  my: t('countries.malaysia'),
  mx: t('countries.mexico'),
  nz: t('countries.newZealand'),
  ph: t('countries.philippines'),
  sg: t('countries.singapore'),
  th: t('countries.thailand'),
  gb: t('countries.unitedKingdom'),
  us: t('countries.unitedStates'),
  vn: t('countries.vietnam')
});

export const COUNTRY_OPTIONS = (t) => [
  { key: 'au', label: t('countries.australia'), value: 'au' },
  { key: 'hk', label: t('countries.hongKong'), value: 'hk' },
  { key: 'id', label: t('countries.indonesia'), value: 'id' },
  { key: 'my', label: t('countries.malaysia'), value: 'my' },
  { key: 'nz', label: t('countries.newZealand'), value: 'nz' },
  { key: 'ph', label: t('countries.philippines'), value: 'ph' },
  { key: 'sg', label: t('countries.singapore'), value: 'sg' },
  { key: 'th', label: t('countries.thailand'), value: 'th' },
  { key: 'gb', label: t('countries.unitedKingdom'), value: 'gb' },
  { key: 'vn', label: t('countries.vietnam'), value: 'vn' }
];

export const DIALLING_CODE_OPTIONS = [
  { key: 'ac', label: 'AC +247', value: 'AC +247' },
  { key: 'ad', label: 'AD +376', value: 'AD +376' },
  { key: 'ae', label: 'AE +971', value: 'AE +971' },
  { key: 'af', label: 'AF +93', value: 'AF +93' },
  { key: 'ag', label: 'AG +1', value: 'AG +1' },
  { key: 'ai', label: 'AI +1', value: 'AI +1' },
  { key: 'al', label: 'AL +355', value: 'AL +355' },
  { key: 'am', label: 'AM +374', value: 'AM +374' },
  { key: 'ao', label: 'AO +244', value: 'AO +244' },
  { key: 'ar', label: 'AR +54', value: 'AR +54' },
  { key: 'as', label: 'AS +1', value: 'AS +1' },
  { key: 'at', label: 'AT +43', value: 'AT +43' },
  { key: 'au', label: 'AU +61', value: 'AU +61' },
  { key: 'aw', label: 'AW +297', value: 'AW +297' },
  { key: 'ax', label: 'AX +358', value: 'AX +358' },
  { key: 'az', label: 'AZ +994', value: 'AZ +994' },
  { key: 'ba', label: 'BA +387', value: 'BA +387' },
  { key: 'bb', label: 'BB +1', value: 'BB +1' },
  { key: 'bd', label: 'BD +880', value: 'BD +880' },
  { key: 'be', label: 'BE +32', value: 'BE +32' },
  { key: 'bf', label: 'BF +226', value: 'BF +226' },
  { key: 'bg', label: 'BG +359', value: 'BG +359' },
  { key: 'bh', label: 'BH +973', value: 'BH +973' },
  { key: 'bi', label: 'BI +257', value: 'BI +257' },
  { key: 'bj', label: 'BJ +229', value: 'BJ +229' },
  { key: 'bl', label: 'BL +590', value: 'BL +590' },
  { key: 'bm', label: 'BM +1', value: 'BM +1' },
  { key: 'bn', label: 'BN +673', value: 'BN +673' },
  { key: 'bo', label: 'BO +591', value: 'BO +591' },
  { key: 'bq', label: 'BQ +599', value: 'BQ +599' },
  { key: 'br', label: 'BR +55', value: 'BR +55' },
  { key: 'bs', label: 'BS +1', value: 'BS +1' },
  { key: 'bt', label: 'BT +975', value: 'BT +975' },
  { key: 'bw', label: 'BW +267', value: 'BW +267' },
  { key: 'by', label: 'BY +375', value: 'BY +375' },
  { key: 'bz', label: 'BZ +501', value: 'BZ +501' },
  { key: 'ca', label: 'CA +1', value: 'CA +1' },
  { key: 'cc', label: 'CC +61', value: 'CC +61' },
  { key: 'cd', label: 'CD +243', value: 'CD +243' },
  { key: 'cf', label: 'CF +236', value: 'CF +236' },
  { key: 'cg', label: 'CG +242', value: 'CG +242' },
  { key: 'ch', label: 'CH +41', value: 'CH +41' },
  { key: 'ci', label: 'CI +225', value: 'CI +225' },
  { key: 'ck', label: 'CK +682', value: 'CK +682' },
  { key: 'cl', label: 'CL +56', value: 'CL +56' },
  { key: 'cm', label: 'CM +237', value: 'CM +237' },
  { key: 'cn', label: 'CN +86', value: 'CN +86' },
  { key: 'co', label: 'CO +57', value: 'CO +57' },
  { key: 'cr', label: 'CR +506', value: 'CR +506' },
  { key: 'cu', label: 'CU +53', value: 'CU +53' },
  { key: 'cv', label: 'CV +238', value: 'CV +238' },
  { key: 'cw', label: 'CW +599', value: 'CW +599' },
  { key: 'cx', label: 'CX +61', value: 'CX +61' },
  { key: 'cy', label: 'CY +357', value: 'CY +357' },
  { key: 'cz', label: 'CZ +420', value: 'CZ +420' },
  { key: 'de', label: 'DE +49', value: 'DE +49' },
  { key: 'dj', label: 'DJ +253', value: 'DJ +253' },
  { key: 'dk', label: 'DK +45', value: 'DK +45' },
  { key: 'dm', label: 'DM +1', value: 'DM +1' },
  { key: 'do', label: 'DO +1', value: 'DO +1' },
  { key: 'dz', label: 'DZ +213', value: 'DZ +213' },
  { key: 'ec', label: 'EC +593', value: 'EC +593' },
  { key: 'ee', label: 'EE +372', value: 'EE +372' },
  { key: 'eg', label: 'EG +20', value: 'EG +20' },
  { key: 'eh', label: 'EH +212', value: 'EH +212' },
  { key: 'er', label: 'ER +291', value: 'ER +291' },
  { key: 'es', label: 'ES +34', value: 'ES +34' },
  { key: 'et', label: 'ET +251', value: 'ET +251' },
  { key: 'fi', label: 'FI +358', value: 'FI +358' },
  { key: 'fj', label: 'FJ +679', value: 'FJ +679' },
  { key: 'fk', label: 'FK +500', value: 'FK +500' },
  { key: 'fm', label: 'FM +691', value: 'FM +691' },
  { key: 'fo', label: 'FO +298', value: 'FO +298' },
  { key: 'fr', label: 'FR +33', value: 'FR +33' },
  { key: 'ga', label: 'GA +241', value: 'GA +241' },
  { key: 'gb', label: 'GB +44', value: 'GB +44' },
  { key: 'gd', label: 'GD +1', value: 'GD +1' },
  { key: 'ge', label: 'GE +995', value: 'GE +995' },
  { key: 'gf', label: 'GF +594', value: 'GF +594' },
  { key: 'gg', label: 'GG +44', value: 'GG +44' },
  { key: 'gh', label: 'GH +233', value: 'GH +233' },
  { key: 'gi', label: 'GI +350', value: 'GI +350' },
  { key: 'gl', label: 'GL +299', value: 'GL +299' },
  { key: 'gm', label: 'GM +220', value: 'GM +220' },
  { key: 'gn', label: 'GN +224', value: 'GN +224' },
  { key: 'gp', label: 'GP +590', value: 'GP +590' },
  { key: 'gq', label: 'GQ +240', value: 'GQ +240' },
  { key: 'gr', label: 'GR +30', value: 'GR +30' },
  { key: 'gt', label: 'GT +502', value: 'GT +502' },
  { key: 'gu', label: 'GU +1', value: 'GU +1' },
  { key: 'gw', label: 'GW +245', value: 'GW +245' },
  { key: 'gy', label: 'GY +592', value: 'GY +592' },
  { key: 'hk', label: 'HK +852', value: 'HK +852' },
  { key: 'hn', label: 'HN +504', value: 'HN +504' },
  { key: 'hr', label: 'HR +385', value: 'HR +385' },
  { key: 'ht', label: 'HT +509', value: 'HT +509' },
  { key: 'hu', label: 'HU +36', value: 'HU +36' },
  { key: 'id', label: 'ID +62', value: 'ID +62' },
  { key: 'ie', label: 'IE +353', value: 'IE +353' },
  { key: 'il', label: 'IL +972', value: 'IL +972' },
  { key: 'im', label: 'IM +44', value: 'IM +44' },
  { key: 'in', label: 'IN +91', value: 'IN +91' },
  { key: 'io', label: 'IO +246', value: 'IO +246' },
  { key: 'iq', label: 'IQ +964', value: 'IQ +964' },
  { key: 'ir', label: 'IR +98', value: 'IR +98' },
  { key: 'is', label: 'IS +354', value: 'IS +354' },
  { key: 'it', label: 'IT +39', value: 'IT +39' },
  { key: 'je', label: 'JE +44', value: 'JE +44' },
  { key: 'jm', label: 'JM +1', value: 'JM +1' },
  { key: 'jo', label: 'JO +962', value: 'JO +962' },
  { key: 'jp', label: 'JP +81', value: 'JP +81' },
  { key: 'ke', label: 'KE +254', value: 'KE +254' },
  { key: 'kg', label: 'KG +996', value: 'KG +996' },
  { key: 'kh', label: 'KH +855', value: 'KH +855' },
  { key: 'ki', label: 'KI +686', value: 'KI +686' },
  { key: 'km', label: 'KM +269', value: 'KM +269' },
  { key: 'kn', label: 'KN +1', value: 'KN +1' },
  { key: 'kp', label: 'KP +850', value: 'KP +850' },
  { key: 'kr', label: 'KR +82', value: 'KR +82' },
  { key: 'kw', label: 'KW +965', value: 'KW +965' },
  { key: 'ky', label: 'KY +1', value: 'KY +1' },
  { key: 'kz', label: 'KZ +7', value: 'KZ +7' },
  { key: 'la', label: 'LA +856', value: 'LA +856' },
  { key: 'lb', label: 'LB +961', value: 'LB +961' },
  { key: 'lc', label: 'LC +1', value: 'LC +1' },
  { key: 'li', label: 'LI +423', value: 'LI +423' },
  { key: 'lk', label: 'LK +94', value: 'LK +94' },
  { key: 'lr', label: 'LR +231', value: 'LR +231' },
  { key: 'ls', label: 'LS +266', value: 'LS +266' },
  { key: 'lt', label: 'LT +370', value: 'LT +370' },
  { key: 'lu', label: 'LU +352', value: 'LU +352' },
  { key: 'lv', label: 'LV +371', value: 'LV +371' },
  { key: 'ly', label: 'LY +218', value: 'LY +218' },
  { key: 'ma', label: 'MA +212', value: 'MA +212' },
  { key: 'mc', label: 'MC +377', value: 'MC +377' },
  { key: 'md', label: 'MD +373', value: 'MD +373' },
  { key: 'me', label: 'ME +382', value: 'ME +382' },
  { key: 'mf', label: 'MF +590', value: 'MF +590' },
  { key: 'mg', label: 'MG +261', value: 'MG +261' },
  { key: 'mh', label: 'MH +692', value: 'MH +692' },
  { key: 'mk', label: 'MK +389', value: 'MK +389' },
  { key: 'ml', label: 'ML +223', value: 'ML +223' },
  { key: 'mm', label: 'MM +95', value: 'MM +95' },
  { key: 'mn', label: 'MN +976', value: 'MN +976' },
  { key: 'mo', label: 'MO +853', value: 'MO +853' },
  { key: 'mp', label: 'MP +1', value: 'MP +1' },
  { key: 'mq', label: 'MQ +596', value: 'MQ +596' },
  { key: 'mr', label: 'MR +222', value: 'MR +222' },
  { key: 'ms', label: 'MS +1', value: 'MS +1' },
  { key: 'mt', label: 'MT +356', value: 'MT +356' },
  { key: 'mu', label: 'MU +230', value: 'MU +230' },
  { key: 'mv', label: 'MV +960', value: 'MV +960' },
  { key: 'mw', label: 'MW +265', value: 'MW +265' },
  { key: 'mx', label: 'MX +52', value: 'MX +52' },
  { key: 'my', label: 'MY +60', value: 'MY +60' },
  { key: 'mz', label: 'MZ +258', value: 'MZ +258' },
  { key: 'na', label: 'NA +264', value: 'NA +264' },
  { key: 'nc', label: 'NC +687', value: 'NC +687' },
  { key: 'ne', label: 'NE +227', value: 'NE +227' },
  { key: 'nf', label: 'NF +672', value: 'NF +672' },
  { key: 'ng', label: 'NG +234', value: 'NG +234' },
  { key: 'ni', label: 'NI +505', value: 'NI +505' },
  { key: 'nl', label: 'NL +31', value: 'NL +31' },
  { key: 'no', label: 'NO +47', value: 'NO +47' },
  { key: 'np', label: 'NP +977', value: 'NP +977' },
  { key: 'nr', label: 'NR +674', value: 'NR +674' },
  { key: 'nu', label: 'NU +683', value: 'NU +683' },
  { key: 'nz', label: 'NZ +64', value: 'NZ +64' },
  { key: 'om', label: 'OM +968', value: 'OM +968' },
  { key: 'pa', label: 'PA +507', value: 'PA +507' },
  { key: 'pe', label: 'PE +51', value: 'PE +51' },
  { key: 'pf', label: 'PF +689', value: 'PF +689' },
  { key: 'pg', label: 'PG +675', value: 'PG +675' },
  { key: 'ph', label: 'PH +63', value: 'PH +63' },
  { key: 'pk', label: 'PK +92', value: 'PK +92' },
  { key: 'pl', label: 'PL +48', value: 'PL +48' },
  { key: 'pm', label: 'PM +508', value: 'PM +508' },
  { key: 'pr', label: 'PR +1', value: 'PR +1' },
  { key: 'ps', label: 'PS +970', value: 'PS +970' },
  { key: 'pt', label: 'PT +351', value: 'PT +351' },
  { key: 'pw', label: 'PW +680', value: 'PW +680' },
  { key: 'py', label: 'PY +595', value: 'PY +595' },
  { key: 'qa', label: 'QA +974', value: 'QA +974' },
  { key: 're', label: 'RE +262', value: 'RE +262' },
  { key: 'ro', label: 'RO +40', value: 'RO +40' },
  { key: 'rs', label: 'RS +381', value: 'RS +381' },
  { key: 'ru', label: 'RU +7', value: 'RU +7' },
  { key: 'rw', label: 'RW +250', value: 'RW +250' },
  { key: 'sa', label: 'SA +966', value: 'SA +966' },
  { key: 'sb', label: 'SB +677', value: 'SB +677' },
  { key: 'sc', label: 'SC +248', value: 'SC +248' },
  { key: 'sd', label: 'SD +249', value: 'SD +249' },
  { key: 'se', label: 'SE +46', value: 'SE +46' },
  { key: 'sg', label: 'SG +65', value: 'SG +65' },
  { key: 'sh', label: 'SH +290', value: 'SH +290' },
  { key: 'si', label: 'SI +386', value: 'SI +386' },
  { key: 'sj', label: 'SJ +47', value: 'SJ +47' },
  { key: 'sk', label: 'SK +421', value: 'SK +421' },
  { key: 'sl', label: 'SL +232', value: 'SL +232' },
  { key: 'sm', label: 'SM +378', value: 'SM +378' },
  { key: 'sn', label: 'SN +221', value: 'SN +221' },
  { key: 'so', label: 'SO +252', value: 'SO +252' },
  { key: 'sr', label: 'SR +597', value: 'SR +597' },
  { key: 'ss', label: 'SS +211', value: 'SS +211' },
  { key: 'st', label: 'ST +239', value: 'ST +239' },
  { key: 'sv', label: 'SV +503', value: 'SV +503' },
  { key: 'sx', label: 'SX +1', value: 'SX +1' },
  { key: 'sy', label: 'SY +963', value: 'SY +963' },
  { key: 'sz', label: 'SZ +268', value: 'SZ +268' },
  { key: 'ta', label: 'TA +290', value: 'TA +290' },
  { key: 'tc', label: 'TC +1', value: 'TC +1' },
  { key: 'td', label: 'TD +235', value: 'TD +235' },
  { key: 'tg', label: 'TG +228', value: 'TG +228' },
  { key: 'th', label: 'TH +66', value: 'TH +66' },
  { key: 'tj', label: 'TJ +992', value: 'TJ +992' },
  { key: 'tk', label: 'TK +690', value: 'TK +690' },
  { key: 'tl', label: 'TL +670', value: 'TL +670' },
  { key: 'tm', label: 'TM +993', value: 'TM +993' },
  { key: 'tn', label: 'TN +216', value: 'TN +216' },
  { key: 'to', label: 'TO +676', value: 'TO +676' },
  { key: 'tr', label: 'TR +90', value: 'TR +90' },
  { key: 'tt', label: 'TT +1', value: 'TT +1' },
  { key: 'tv', label: 'TV +688', value: 'TV +688' },
  { key: 'tw', label: 'TW +886', value: 'TW +886' },
  { key: 'tz', label: 'TZ +255', value: 'TZ +255' },
  { key: 'ua', label: 'UA +380', value: 'UA +380' },
  { key: 'ug', label: 'UG +256', value: 'UG +256' },
  { key: 'uk', label: 'UK +44', value: 'UK +44' },
  { key: 'us', label: 'US +1', value: 'US +1' },
  { key: 'uy', label: 'UY +598', value: 'UY +598' },
  { key: 'uz', label: 'UZ +998', value: 'UZ +998' },
  { key: 'va', label: 'VA +39', value: 'VA +39' },
  { key: 'vc', label: 'VC +1', value: 'VC +1' },
  { key: 've', label: 'VE +58', value: 'VE +58' },
  { key: 'vg', label: 'VG +1', value: 'VG +1' },
  { key: 'vi', label: 'VI +1', value: 'VI +1' },
  { key: 'vn', label: 'VN +84', value: 'VN +84' },
  { key: 'vu', label: 'VU +678', value: 'VU +678' },
  { key: 'wf', label: 'WF +681', value: 'WF +681' },
  { key: 'ws', label: 'WS +685', value: 'WS +685' },
  { key: 'xk', label: 'XK +383', value: 'XK +383' },
  { key: 'ye', label: 'YE +967', value: 'YE +967' },
  { key: 'yt', label: 'YT +262', value: 'YT +262' },
  { key: 'za', label: 'ZA +27', value: 'ZA +27' },
  { key: 'zm', label: 'ZM +260', value: 'ZM +260' },
  { key: 'zw', label: 'ZW +263', value: 'ZW +263' }
];

export const CURRENCY_SYMBOLS = {
  aud: 'A$',
  gbp: '£',
  myr: 'RM',
  nzd: '$',
  php: '₱',
  sgd: 'S$',
  idr: 'Rp',
  vnd: '₫'
};

export const ALLOWED_REDIRECT_PATHS = [
  /^home$/,
  /^post-job$/,
  /^jobs\/[a-zA-Z0-9-]*\/payment-success/,
  /^jobs\/[a-zA-Z0-9-]*\/details$/,
  /^jobs\/[a-zA-Z0-9-]*\/upgrade-job/
];

export const BUSINESS_REGISTRATION_NUMBER_FIELD_NAME = {
  sg: 'Company Registration Number',
  vn: 'Số giấy phép kinh doanh (GPKD)',
  au: 'Australian Business Number (ABN)',
  br: 'Cadastro Nacional da Pessoa Jurídica (CNPJ)',
  gb: 'Company Number',
  hk: 'Business Registration Number',
  id: 'NPWP atau Surat Izin Usaha',
  mx: 'Registro Federal de Contribuyentes - RFC',
  my: 'Company Registration Number',
  nz: 'New Zealand Business Number (NZBN)',
  ph: 'PHBN (Philippines Business Number)',
  th: 'เลขทะเบียนนิติบุคคล',
  us: 'Employer Identification Number (EIN)'
};
