import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';
import { ScrollableTabListProvider } from '@common/components/ScrollableTabListProvider';
import { Stack } from '@components/Stack';
import { ItemSeparator } from './shared/ItemSeparator';
import { ListBottomComponent } from './shared/ListBottomComponent';
import { ListHeaderComponent } from './shared/ListHeaderComponent';
import { ListItemCard } from './shared/ListItemCard';

export const FlatListWithTabsExample = ({ children }: PropsWithChildren) => {
  const componentRef = React.useRef<View | null>(null);

  const listTabGeneric = (letter: string) => [
    { id: '1', title: `1 (List ${letter})` },
    { id: '2', title: `2 (List ${letter})` },
    { id: '3', title: `3 (List ${letter})` },
    { id: '4', title: `4 (List ${letter})` },
    { id: '5', title: `5 (List ${letter})` },
    { id: '6', title: `6 (List ${letter})` },
    { id: '7', title: `7 (List ${letter})` }
  ];

  return (
    <Stack tone="warning">
      <ScrollableTabListProvider
        tabOptions={[
          { id: '1', label: 'Tab A', data: listTabGeneric('A') },
          { id: '2', label: 'Tab B', data: listTabGeneric('B') },
          { id: '3', label: 'Tab C', data: listTabGeneric('C') }
        ]}
        renderItem={({ item }) => <ListItemCard title={item.title} />}
        ItemSeparatorComponent={ItemSeparator}
        ListHeaderComponent={
          <ListHeaderComponent componentRef={componentRef}>
            {children}
          </ListHeaderComponent>
        }
        ListFooterComponent={
          <ListBottomComponent componentRef={componentRef} />
        }
      />
    </Stack>
  );
};
