import { AppealStatus } from '@seek/je-shared-data/lib/types/authentication';

export const pathUserHasLimitedAccessTo = ({
  countryCode,
  userDetails: { appealStatus, emailVerified },
  hasDraftData
}) => {
  // hasDraftData is only derived from the fact there is data in local storage for a new job/business
  // this could cause an edge for if a user starts the authenticated post job process but then leave to edit there email
  // which would result in them being redirected to verify-email page in the signup flow and not account verify email
  if (!emailVerified && hasDraftData) {
    return `/${countryCode}/verify-email`;
  }

  if (!emailVerified) {
    return `/${countryCode}/account/verify-email`;
  }

  if (appealStatus === AppealStatus.REQUESTED) {
    return `/${countryCode}/account/verify-account-details`;
  }

  if (appealStatus === AppealStatus.SUBMITTED) {
    return `/${countryCode}/account/verify-account-details-success`;
  }
};
