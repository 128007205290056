import { useLocation } from 'react-router-native';
import { useGetPostJobLink } from '@common/hooks/appPageLinks';
import { useRedirect } from '@common/hooks/useRedirect';
import { updateSlug } from '@src/lib/window';

const useRedirectToUpgradeJobPage = () => {
  const { redirect } = useRedirect();

  return (jobId: string, countryCode: string) => {
    return redirect(
      `/${countryCode}/jobs/${jobId}/upgrade-job?fromPostJob=true`
    )();
  };
};

const useRedirectToPostJobPage = () => {
  const { redirect } = useRedirect();
  const postJobLink = useGetPostJobLink();

  return () => redirect(postJobLink);
};

const useUpdateDepricatedUrls = () => {
  const { pathname } = useLocation();
  return () => {
    if (pathname.includes('select-job-role')) {
      updateSlug('post-job', window, false);
    }
  };
};

export const useSignUp = () => {
  const redirectToUpgradeJobPage = useRedirectToUpgradeJobPage();
  const redirectToPostJobPage = useRedirectToPostJobPage();
  const updateDepricatedUrls = useUpdateDepricatedUrls();

  return {
    redirectToUpgradeJobPage,
    redirectToPostJobPage,
    updateDepricatedUrls
  };
};
