import React from 'react';
import { ComponentRef } from './lib';

type ScrollableListContextProps = {
  scrollToTop: () => void;
  scrollToBottom: () => void;
  scrollToComponent: (ref: ComponentRef) => void;
  scrollToIndex: (index: number) => void;
};

const throwContextError = () => {
  throw new Error('Called outside ScrollableListContext not allowed');
};

export const ScrollableListContext =
  React.createContext<ScrollableListContextProps>({
    scrollToTop: throwContextError,
    scrollToBottom: throwContextError,
    scrollToComponent: throwContextError,
    scrollToIndex: throwContextError
  });

export const useScrollableList = () => React.useContext(ScrollableListContext);
