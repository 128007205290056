import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { isNative } from '@common/helpers/detection';
import { SupportedCountryCode } from '@seek/je-shared-data/lib/types/brand';
import Div from '../../../../../common/components/Div/Div';
import CardFieldInput from '../../../../../common/components/FormElements/CardFieldInput/CardFieldInput.native';
import Dropdown from '../../../../../common/components/FormElements/Dropdown/Dropdown';
import { COUNTRY_OPTIONS } from '../../../../constants/data';
import { IndonesianBankTransferInput } from '../IndonesianBankTransferInput';

@connect((state) => {
  const {
    payment: {
      intent: { jobCountry }
    }
  } = state;

  const shouldDisplayIdBankTransfer =
    jobCountry === SupportedCountryCode.Indonesia;

  return {
    shouldDisplayCardFields: !shouldDisplayIdBankTransfer,
    shouldDisplayIdBankTransfer
  };
})
@withTranslation()
export default class PaymentInputFields extends Component {
  static propTypes = {
    onReady: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    'data-test-key': PropTypes.string,
    t: PropTypes.func.isRequired,
    currentValues: PropTypes.object,
    shouldDisplayCardFields: PropTypes.bool,
    shouldDisplayIdBankTransfer: PropTypes.bool
  };

  render() {
    const {
      onChange,
      onReady,
      'data-test-key': dataTestKey,
      t,
      shouldDisplayCardFields,
      shouldDisplayIdBankTransfer
    } = this.props;

    const isWeb = !isNative();
    const shouldDisplayStripeProvidedFields =
      isWeb || (isNative() && shouldDisplayCardFields);
    const shouldDisplayCustomCardFields = isNative() && shouldDisplayCardFields;
    const shouldDisplayCustomIdBankTransferFields =
      isNative() && shouldDisplayIdBankTransfer;

    return (
      <Div>
        {shouldDisplayStripeProvidedFields && (
          // Magical Stripe Elements component, not actually just for card details
          // Also shows bank transfer
          <Field
            component={CardFieldInput}
            name="cardDetails"
            fieldId="cardDetails"
            label={
              isNative() ? t('checkoutForm.card.fieldLabel.cardDetails') : ''
            }
            validationMessages={t('validations.payment.cardDetails', {
              returnObjects: true
            })}
            data-test-key={dataTestKey}
            onChange={onChange}
            onReady={onReady}
          />
        )}

        {shouldDisplayCustomCardFields && (
          <Field
            component={Dropdown}
            name="country"
            fieldId="country"
            label={t('createBusinessForm.fieldLabel.country')}
            options={COUNTRY_OPTIONS(t)}
          />
        )}

        {shouldDisplayCustomIdBankTransferFields && (
          <IndonesianBankTransferInput onReady={onReady} />
        )}
      </Div>
    );
  }
}
